<template>
  <body>
    <div class="printwarrant">
      <!--店信息-->
      <h1>{{ shopInfoData.Name }}</h1>

      <hr />

      <!--客户信息-->
      <b style="font-size: 10pt">{{
            tools.GetPrintLanguageValue("web.顾客")
          }}</b>
      <p
        v-if="
          repairInfoData.ClientInfo.Name != null &&
          repairInfoData.ClientInfo.Name != ''
        "
      >
        {{ tools.GetPrintLanguageValue("web.名称") }}:
        {{ repairInfoData.ClientInfo.Name }}
      </p>
      <p v-if="repairInfoData.ClientInfo.Address === undefined">
        No hay dirección
      </p>
      <p v-if="repairInfoData.ClientInfo.Address !== undefined">
        {{ repairInfoData.ClientInfo.Address }}
      </p>
      <p
        v-if="
          repairInfoData.ClientInfo.DocNumber != null &&
          repairInfoData.ClientInfo.DocNumber != ''
        "
      >
        <span class="uppercase"
          >{{ repairInfoData.ClientInfo.DocTypeName }}
        </span>
        {{ repairInfoData.ClientInfo.DocNumber }}
      </p>
      <p
        v-if="
          repairInfoData.ClientInfo.PhoneNumber != null &&
          repairInfoData.ClientInfo.PhoneNumber != ''
        "
      >
        {{ tools.GetPrintLanguageValue("web.手机号码") }}.{{
          repairInfoData.ClientInfo.PhoneNumber
        }}
      </p>

      <div
        v-for="(item, index) in repairInfoData.RepairMobileInfos"
        :key="index"
        v-show="currentRepairItemIds.indexOf(item.Id) > -1"
      >
        <hr />
        <!--维修信息-->
        <b style="font-size: 11pt">{{
            tools.GetPrintLanguageValue("web.维修")
          }}</b>
        <div class="fl-layout">
          <div class="fl-grow">
            <p>
              <b>
                {{ tools.GetPrintLanguageValue("web.保修时间") }}:
                {{ item.WarrantyStartTimeFormat }}
              </b>
            </p>
            <p>
              {{ tools.GetPrintLanguageValue("web.品牌") }}/{{
                tools.GetPrintLanguageValue("web.型号")
              }}:{{ item.BrandAndModel }} {{ item.ModelPropertiesFormat }}
            </p>
            <p v-if="item.ImelNumber != null && item.ImelNumber != ''">
              IMEI/SN:{{ item.ImelNumber }}
            </p>
            <p v-if="item.Password != null && item.Password != ''">
              {{ tools.GetPrintLanguageValue("web.密码") }}:{{ item.Password }}
            </p>
            <p>{{ item.Comment }}</p>
          </div>
        </div>

        <hr />

        <table width="100%">
          <tr>
            <th></th>
            <th style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.数量") }}
            </th>
            <th style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.单价") }}
            </th>
            <th style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.合计") }}
            </th>
          </tr>

          <tr v-for="(data, index) in item.RepairItems" :key="index">
            <td>
              <span class="fl-grow">{{ data.Name }}</span>
            </td>
            <td>{{ data.Number }}</td>
            <td>{{ data.PriceFormat }}</td>
            <td>{{ data.TotalFormat }}</td>
          </tr>
        </table>
        <hr    v-if="shopInfoData.PrintMaintainTaxRate"/>
        <table
          width="100%"
          style="font-size: 8pt"
          v-if="shopInfoData.PrintMaintainTaxRate"
        >
          <tr>
            <td style="width: 40px">
              {{ tools.GetPrintLanguageValue("web.金额") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.税率") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.税额") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.合计") }}
            </td>
          </tr>

          <tr v-for="(data, index) in item.TaxRates" :key="index">
            <td>
              {{ data.AmountFormat }}
            </td>
            <td>{{ data.TaxRate }}</td>
            <td>{{ data.TaxAmountFormat }}</td>
            <td>{{ data.TotalFormat }}</td>
          </tr>
        </table>

         

        <div class="fl-layout">
          <p class="fl-grow" style="font-size: 10pt">
            <b> {{ tools.GetPrintLanguageValue("web.合计") }} </b>
          </p>
          <p class="fl-not" style="font-size: 11pt">
            <b>
              {{ repairInfoData.TotalFormat }}
            </b>
          </p>
        </div>
      </div>

      <!--     <hr />

      <div class="fl-layout">
        <p class="fl-grow">
          <b> {{ tools.GetPrintLanguageValue("web.合计") }}</b>
        </p>
        <p class="fl-not">
          <b> {{ repairInfoData.TotalFormat }} </b>
        </p>
      </div> -->

      <hr />

      <div class="barcode-wrapper">
        <svg id="barcode"></svg>
      </div>

      <hr />

      <p v-if="!settingModel.DotPrintDrawer">
        {{ tools.GetPrintLanguageValue("web.操作人") }}: {{ operatorName }}
      </p>
      <br />

      <div v-html="printAndTermData.WarrantyTerms"></div>

      <br />
      <div class="fl-layout">
        <div class="fl-grow">
          <h3>Firma &#x2F; Sello Establecimiento</h3>
          <br /><br />
          <div class="print-signature-box"></div>
        </div>
      </div>
    </div>
  </body>
</template>
<script setup>
import "../PrintCss/print.css";
import "../PrintCss/printeighty.css";
import "../PrintCss/custom-flex.css";
import tools from "../../../commons/tools.js";
import axios from "../../../commons/AxiosMethod.js";
import JsBarcode from "jsbarcode";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const currentRepairId = ref("");
// 店铺信息
const shopInfoData = ref({
  // Name: "维修侠", // 店铺名称
  // Address: "四川成都", // 地址
  // NieOrCifNumber: "",
  // TelephoneNo: "0811-12543574", // 联系电话
});
// 客户信息
const clientInfoData = ref({
  Name: "客户姓名",
  Address: "很长很长的地址一大串啊啊啊啊啊啊啊啊",
  DocTypeName: "DNI", // 证件类型名称
  DocNumber: "528485A", // 证件类型名称
  PhoneNumber: "0325-52143",
});
const repairItemDataInfo = ref([
  {
    Name: "设备进水", // 维修项名称
    Number: 1, // 数量
    Price: 2.0, // 单价
    TotalPrice: 2.0, // 总价
  },
  {
    Name: "更换屏幕", // 维修项名称
    Number: 1, // 数量
    Price: 4.0, // 单价
    TotalPrice: 4.0, // 总价
  },
]); // 维修的项目
const totalAllPrice = ref(6.0); // 统计的价格
// 维修单信息
const repairInfoData = ref({
  ClientInfo: {},
  ClientId: "",
  ImelNumber: "123545875", // IEM号
  Password: "123456", // 密码
  WarrantyStartTime: "", // 保修开始日期
  BrandAndModel: "牌子/型号", // 牌子/型号
  RepairNo: "202311052149", // 编号
});
// 当前操作员名称
const operatorName = ref("");
// 打印设置数据
const printAndTermData = ref({
  Id: undefined,
  DotPrintDrawer: false,
  ReserveTerm: "",
  ConditionsOfRepair: "",
  WarrantyTerms: "<p>This is <strong>rich text</strong> content.</p>", // 保修条款
  Remark: "",
});

const settingModel = ref({
  DotPrintCounterfoil: localStorage.getItem("DotPrintCounterfoil") == "yes",
  DotPrintDrawer: localStorage.getItem("DotPrintDrawer") == "yes",
});

const currentRepairItemId = ref("");
const currentRepairItemIds = ref([]);
// 页面加载
onMounted(() => {
  currentRepairId.value = route.query.id;
  currentRepairItemId.value = route.query.itemId;
  var itemIds = route.query.itemIds;

  currentRepairItemIds.value = itemIds.split(',');

  console.log(currentRepairItemIds.value);

  getRepairInfo(); // 维修信息

  loadPrintSetting(); // 打印条款

  loadOtherSetting();
  loadCurrentUserName();
});
const showPrintPage = () => {
  setTimeout(() => {
    window.print();
    setTimeout(() => {
      closeCurrentPage();
    }, 500);
  }, 1500);
};
const initone = () => {
  if (repairInfoData.value.RepairNo) {
    const ts = parseInt(repairInfoData.value.RepairNo.valueOf(), 10);
    JsBarcode("#barcode", ts, {
      width: 2,
      height: 25,
      fontSize: 12,
    });
  }
};

// 获取打印设置
const loadPrintSetting = () => {
  axios.apiMethod(
    "/stores/Setting/GetPrintSettingByLanguageId",
    "get",
    {},
    (result) => {
      if (result.Code === 200) {
        printAndTermData.value = result.Data;
      }
    }
  );
};

// 获取维修单信息
const getRepairInfo = () => {
  var printLanguageId = localStorage.getItem("PrintLanguageId");
  axios.apiMethod(
    "/stores/Repair/GetRepairByIdToLanguageId",
    "get",
    {
      Id: currentRepairId.value,
      ItemId: currentRepairItemId.value,
      languageId: printLanguageId,
    },
    (result) => {
      console.log(result);
      repairInfoData.value = result.Data;
      initone();
     showPrintPage();
    }
  );
};

// 获取客户信息
const getClientInfo = () => {
 
  console.log(repairInfoData.value.ClientId);
  axios.apiMethod(
    "/stores/Client/GetClientById",
    "get",
    {
      Id: repairInfoData.value.ClientId,
    },
    (result) => {
    
      clientInfoData.value = result.Data;
    }
  );
};
// 获取其它设置
const loadOtherSetting = () => {
  axios.apiMethod("/stores/Setting/GetStoreSettings", "get", {}, (result) => {
    if (result.Code === 200) {
      shopInfoData.value = result.Data;
      // console.log(result.Data)
    }
  });
};
// 获取当前登陆人的姓名
const loadCurrentUserName = () => {
  var json = localStorage.getItem("UserInfo");
  var userInfo = JSON.parse(json);
  operatorName.value = userInfo.Name;
};

const closeCurrentPage = () => {
  window.close();
};
</script>
<style scoped>
.app_conetnt {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0); /* 设置新的 box-shadow 属性 */
}
</style>
